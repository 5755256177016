.langauge-switch {
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 0;
  width: 95px;
  height: 30px;
  border-radius: 30px;
  background-color: $brand-color;
  border: 1px solid $brand-color;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;


  a {
    color: $text-white;
    display: inline-block;
    font-weight: 600;
    font-size: .875rem;
    line-height: 28px;
    width: 100%;
    padding-left: 28px;

  }

  a::before {
    position: absolute;
    content:'';
    z-index: 4;
    display: block;
    height: 18px;
    left: 4px;
    width: 18px;
    background-color: $text-white;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    top: 50%;
    transform: translateY(-50%);
  }
}
