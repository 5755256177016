.select_faq {
  width: 150px;
  height: 40px;
  border: 1px solid #d7d7de;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}
.info-tooltip {
  background: $text-white;
  box-shadow: 0px 1px 24px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  & .popover-arrow {
    display: none;
  }
  & .custom-marker li {
    position: relative;
    &::before {
      background-color: #5ede2f;
      top: 10%;
      transform: unset;
    }
  }
}
