.custom_accordion {
    &.accordion-item:last-of-type {
        border-bottom: 0;
    }

    & .accordion-item {
        border-color: rgba(215, 215, 222, 1);
        border-width: 0 0 1px 0;
        & .accordion-button {
            font-size: $font_size_18;
            line-height: 56px;
            color: #1c2025;
            font-weight: 700;
        }
        & .accordion-button:not(.collapsed) {
            background-color: $text-white;
            box-shadow: none;
            font-size: $font_size_18;
            padding-bottom: 0;
        }
        & .accordion-button:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }
    & .first-item:not(.collapsed)::after{
       background-image: none;
    }
}
// custom accordion default sizes
.custom_accordion.faq_main {
  & .accordion-button:not(.collapsed),
  & .accordion-button {
    font-size: 1.5rem;
  }
}

.faq_main {
    & .accordion-item:last-of-type {
        border-bottom: 0;
        border-radius: 20px;
        & .accordion-button {
            border-radius: 20px;
        }
    }
    & .accordion-item:first-of-type {
        border-radius: 20px;
        & .accordion-button {
            border-radius: 20px;
        }
    }
    & .accordion-item {
        border-radius: 20px;
        border-color: rgba(215, 215, 222, 1);
        border-width: 0 0 1px 0;
        & .accordion-button {
            border-radius: 20px;
            font-size: 22px;
            line-height: 56px;
            color: #1c2025;
            font-weight: 700;
            padding-top: 0;
            padding-bottom: 0;
        }
        & .accordion-button:not(.collapsed) {
            background-color: $text-white;
            box-shadow: none;
            color: $brand-color;
        }
        & .accordion-button:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }
}