@charset "UTF-8";
// 1. Configuration and helpers
@import "abstracts/variables",
"abstracts/functions",
"abstracts/mixins";
// 2. Vendors
@import "vendors/normalize";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/select2/dist/css/select2.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../node_modules/magnific-popup/dist/magnific-popup.css";

// 3. Base stuff
@import "base/base",
"base/fonts",
"base/typography",
"base/helpers";
// 4. Layout-related sections
@import "layout/header",
"layout/footer";
// 5. Components
@import "components/button";
@import "components/document_upload";
@import "components/toggle";
@import "components/banner";
@import "components/social_icon";
@import "components/white_card";
@import "components/tabs";
@import "components/pagination";
@import "components/project_card";
@import "components/timeline";
@import "components/breadcrumbs";
@import "components/tags";
@import "components/accordions";
@import "components/imgoverlay";
@import "components/modal";
@import "components/moderator";
@import "components/siteoverview";
@import "components/rating";
@import "components/lists";
@import "components/widgets";

// 6. Page-specific styles
@import "pages/home";
@import "pages/login";
@import "pages/product";
@import "pages/allstories";
@import "pages/generelresources";
@import "pages/media";
@import "pages/register";
@import "pages/profile";
@import "pages/allideas";
@import "pages/ideadetail";
@import "pages/faq";
@import "pages/survey";
@import "pages/splash";
// @import "pages/generelresources";
// 7. Themes
@import "themes/default";
@import "layout/responsive.scss";

@import "../../../media/alertify.js/themes/alertify.core.css";
@import "../../../media/alertify.js/themes/alertify.default.css";

@media (max-width:767px) {
  .view-flow.layout-survey iframe.iframe-resizer {
    min-height:350px !important;
    height:350px !important;
  }
}