.project-card {
  background: $text-white;
  box-shadow: 0px 100px 80px rgba(28, 32, 37, 0.05),
    0px 12.5216px 10.0172px rgba(28, 32, 37, 0.025);
  border-radius: 20px;
  overflow: hidden;
}

.project-detail {
  min-height: 140px;

 .project-title{
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  }

  $project-blurb-lines-n : 2; // Used for mininum number of lines to show

  p {
    display: -webkit-box;
    -webkit-line-clamp:  $project-blurb-lines-n;
    -webkit-box-orient: vertical;
    overflow: hidden;

    min-height: calc($font_size_18 * $project-blurb-lines-n * var(--bs-body-line-height));

    @media (max-width: 1600px) {
      min-height: calc($font_size_18_1600 * $project-blurb-lines-n * var(--bs-body-line-height));
    }
  }
}

// This is used on multiple pages. Home/Stories
.project-img {
  min-height: 140px;
  img {
    height: 203px;
    object-fit: cover;
  }
}
