
// Resource library widget
.widget-list {
  li svg {
    min-width: 24px !important;
  }
}

.theme-box {
  p,
  li {
    font-size: $font-size-16;
  }
  .fw-800 {
    font-weight: 600; //800
  }
  h4 {
    font-size: 1rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  .icons_2 svg {
    width: 30px;
  }
  .box_desc {
    font-size: 1rem;
  }
}

.pv-content-widget{
  padding-left: 0 !important;
  padding-right: 0 !important;

  &.white-card .iframe-container{
    overflow: hidden;
    position: relative;
    width: 100%;

    &::after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}