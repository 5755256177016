.pv-pagination {
  .page {
   // @extend .text-uppercase;
    @extend .fs-18;
    @extend .px-1;
  }
  &.search{
    margin-top: 1.8rem;
    > ul{
      @extend .list-inline;
      > li{
        @extend .list-inline-item;
        @extend .px-4;
      }
    }
  }
}