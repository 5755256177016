.social-share-icons {
  &.home {
    right: 40px;
  }

  & a {
    border: 2px solid $white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    color: $white !important;
    font-size: 12px;
    text-decoration: none !important;
    @include media-breakpoint-up(md) {
      width: 40px;
      height: 40px;
      i,span {
        font-size: 18px;
      }
    }

    &.custom {
      img {
        width: 20px;
        height: 16px;
      }
    }
  }

  & .text {
    font-weight: 600;
    color: #a9afb6;
  }

  &.footer-social {
    a {
      border: none;
      width: 32px;
      height: 32px;
      i{
        font-size: 1rem;
      }
      img{
        width: 32px;
        height: 32px;
      }
      &.twitter{
        background-color: #1DA1F2;
        margin-left: 2px;
      }
      &.linkedin{
        background-color: #0077B5;
        margin-left: 2px;
      }
      &.email{
        background-color: #657786;
      }
    }

    .text {
      color: #636363;
    }
  }
}

.social-share-icons.top-20 {
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.box-icons.social-share-icons {
  & a {
    border-color: $text-color;
    color: $text-color !important;
  }

  & a:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.share-tooltip {
  background: $text-white;
  box-shadow: 0px 1px 24px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  & .popover-arrow {
    display: none;
  }

  & .icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;

  }

  & .envelope-bg {
    background: #3be8b0;
  }

  & .link-bg {
    background: $brand-color;
  }

  & .fb-bg {
    background: #1877f2;
  }

  & .linked-bg {
    background: #007ab9;
  }

  & .twitter-bg {
    background: #03a9f4;
  }

  & .insta-bg {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  }

  & .click {
    cursor: pointer;
  }
}

/*default_widget_socialmedia.php*/
.widget-social-media {

  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      //padding: 0 7px 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      img {
        width: 34px;
        margin: 0 .5rem 0;
      }

      &.linkedin {
        background-color: #0077B5;
        border-radius: 50%;
        width: 34px;
        height: 35px;
        margin: 0 7px 0;

        svg {
          margin: 7px 0 0 7px;
        }
      }

      &.email {
        background-color: #657786;

        svg {
          padding: 1px;
        }
      }

      &.twitter {
        background-color: #1DA1F2;

        svg {
          margin: 8px 0 0 7px;
        }
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

