.rating-wrapper {
  direction: rtl !important;
  position: relative;
  z-index: 1;
  label {
    color: #e1e6f6;
    cursor: pointer;
    z-index: 1;
    padding: 0 4rem 0 0;
    font-size: 2rem;
    transition: color 0.5s;
    display: inline-flex;
  }
  svg {
    -webkit-text-fill-color: transparent;
    -webkit-filter: drop-shadow (4px 1px 6px rgba(198, 206, 237, 1));
    filter: drop-shadow(5px 1px 3px rgba(198, 206, 237, 1));
  }
  input {
    height: 100%;
    width: 100%;
  }
  input {
    display: none;
  }
  label:hover,
  label:hover ~ label,
  input:checked ~ label {
    color: $brand-color;
  }
  label:hover,
  label:hover ~ label,
  input:checked ~ label {
    color: $brand-color;
  }
  i {
    z-index: 1;
  }
}

.rating-wrapper:after {
  width: 100%;
  height: 15px;
  content: "";
  z-index: 0;
  background: #d7d7de;
  border-radius: 15px;
  position: absolute;
  top: 10px;
  left: 0px;
}

.table.table-survey {
  width: 380px;
  > :not(:first-child) {
    border-top: 0;
  }
}

.widget-type-survey {
  .embed-survey {
    height: 100%;
    iframe {
      //min-height: 400px;
      .question-text *{
        font-size: 19.2px;
      }
    }
  }
}

.view-flow.layout-survey{
  iframe.iframe-resizer {
    min-height: 400px;
    height: 55vh !important
  }
}

.consultation-survey-wrapper {
  &.embed-survey {
    height: 100%;
    iframe {
      min-height: 400px !important;
      .question-text *{
        font-size: 19.2px;
      }
    }
  }

  //background images
  background-color: transparent;

  iframe.first-page {
    // min-height: 400px !important;
  }
}

@media screen and (max-width: 767px) {
  .view-flow.layout-survey{
    iframe.iframe-resizer {
      min-height:350px !important;
      height:350px !important;
    }
  }
}


