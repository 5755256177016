.document-form {

  .file-chosen{
    overflow: hidden;
    white-space: nowrap;

  }

  .file-chosen,
  .browse_label{
    font-size: $font-size-12 !important;
  }
}