.ellipsis_txt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resource-list{
  .bg-yellow{
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    line-height: 30px;
  }
  ul > li:not(:last-child){
    border-bottom: 1px solid #dee2e6;
  }
}

.border-50{
  svg{
    border-radius: 50%;
  }
}