.tags {
  margin-top: 1rem;
    & li a {
        background: #F9F9F9;
        border: 1px solid #D7D7DE;
        border-radius: 20px;
        font-weight: 600;
        font-size: $font_size_14;
        color: $text-color;
    }
}

.tags.border-after {
    &::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 38px;
        background-color: rgba(186, 188, 189, 1);
        right: 66%;
    }
}