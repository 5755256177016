.border-after {
    width: 1px;
    height: 38px;
    background-color: #babcbd;
    position: relative;
    top: 12px;
    margin: 0 14px;
}

.comment-tooltip {
    background: #ffffff;
    box-shadow: 0px 1px 24px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    & .popover-arrow {
        display: none;
    }
}

.write_your_comment_box {
    width: 400px;
    z-index: 2;
    background: #fff;
    border-radius: 20px;
    padding: 1rem;
    top: 46px;
    left: -10px;
    box-shadow: 0 1px 24px 5px #00000040;
}

.write_your_comment_box textarea {
    border-radius: 10px;
}

.reply-tools-wrapper{
  .custom-popover,
  .custom-comment-popver {
    bottom: 58% !important;
  }
}

// Used to the popup display
.custom-popover,
.custom-comment-popver {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 1px 24px 5px #00000040;
    min-width: 150px;
    padding: 1rem;
    z-index: 1;

   > a {
    display: block;
     white-space: nowrap;
  }
}

.custom-popover,
.custom-comment-popver.story-detail {
  right: 0;
}
//////////////////////////////////////

.lamb-svg{
  min-width: 33px;
}

.rating_box {
    background: rgba(28, 32, 37, 1);
    border-radius: 30px;
    & .bs-popover-top>.popover-arrow:after {
        border-top-color: #000;
    }
    & .popover-body {
        color: #212529;
        padding: 4px 30px;
    }
}

.rating_box.bs-popover-top>.popover-arrow:after,
.rating_box.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow:after {
    border-top-color: #1c2025 !important;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

@include media-breakpoint-up(lg){
  .social-share-icons.inner_pages.home.idea-detail {
    top: 44%;
    //transform: translateY(-50%);
  }
}


.single-reply {
  background-color: #efefef;
  border-radius: 10px;

  textarea {
    border-radius: 10px;
  }
}

.challenge-closes-text {
  color: white;
}

.idea-detail-top-ideas{
  .fs-18.short-para-2-line p{
    display: -webkit-box;
    -webkit-line-clamp:  2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    min-height: calc(var(--bs-body-font-size) * 2 * var(--bs-body-line-height));

    @media (max-width: 1600px) {
      min-height: calc(var(--bs-body-font-size) * 2 * var(--bs-body-line-height));
    }
  }
}

@media (min-width: 300px) and (max-width: 400px){
  .detail-card .stats-container{
    margin-left: -5px;
    margin-right: -15px;
  }
}

