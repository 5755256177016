// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
@media (min-width: 300px) {
    .footer-desc {
        font-size: 12px;
    }
}

@media (min-width: 992px) {
    .border-line-2 {
        border-right: 1px solid #a9afb6;
        padding-right: 40px;
    }
    .side_bar_gap {
        padding-left: 40px;
    }
    .fs-48 {
        font-size: 2.3rem;
    }
    .footer-desc {
        font-size: $font-size-14
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: $max-width-custom;
    }
}

@media (min-width: 1500px) {
    .ms-xxxl-auto {
        margin-left: auto !important;
    }
    .col-custom-40 {
        width: 40%;
        flex: none;
    }
    .col-custom-60 {
        width: 60%;
        flex: none;
    }
    .col-xxxl-1 {
        width: 16%;
        flex: none;
    }
    .px-xxxl-5 {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (min-width: 1600px) {
    .banner {
        .home-banner-title {
            font-size: 72px;
        }
        h4 {
            width: 50%;
        }
    }
}

@media (min-width: 1920px) {
    .fs-48 {
        font-size: $font-size-48;
    }
    .footer-desc {
        font-size: 1rem;
    }
}