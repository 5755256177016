.my_accordion {
    & .accordion-button:not(.collapsed):after {
        transform: rotate(0);
    }
    & .accordion-button:not(.collapsed) {
        color: $brand-color;
        background-color: transparent;
        box-shadow: none;
    }
    & .accordion-button:after {
        margin-left: 10px;
    }
    & .accordion-button:focus {
        box-shadow: none;
    }
    & .accordion-button:not(.collapsed):after {
        background-image: url(/templates/mysaytoday/images/download.svg);
    }
}

.tags_all_ideas {
    display: flex;
    .stat-icon{
      width: 40px;
      height: 40px;
      //border: 1px solid #c7c7c7;
      border-radius: 5px 0 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 20px;
        &.width-30{
          width: 25px;
        }
        &.width-35{
          width: 30px;
        }
      }
    }

    & .first {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        color: #000;
        border-radius: 0;
        font-size: 1.1rem !important;
    }
    & .last {
        //border: 1px solid #c7c7c7;
        border-radius: 0px 5px 5px 0px;
        height: 40px;
        line-height: 40px;
        &.underline, .underline{
          text-decoration: underline;
        }
      &.active{
       background-color:  #efefef !important;
      }
    }
    &>div:not(:last-child) {
        margin-right: 10px;
    }
    & .fs-5 {
        font-size: 1.125rem !important;
    }
    & .fs-18 {
        font-size: $font-size-14;
    }
}

.time_remaining_box {
    li {
        div {
            font-size: 22px;
            span {
                font-size: 16px;
            }
        }
    }
}

.profile-popup {
    border-radius: 20px;
    & .popover-body {
        padding: 0;
    }
    & .popover-arrow {
        display: none;
    }
    & .profile-img {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;
    }
    & .yellow-cover {
        height: 55px;
        background: $brand-color;
        border-radius: 20px 20px 0px 0px;
        margin-bottom: -50px;
    }
}

.get-involved-cta{
  h2 {
  }
}

.email-cta-banner {
    background-image: url(/templates/mysaytoday/images/subscribe.png);
    background-position: right;
    padding: 30px 0;
}

.all-ideas-banner {
    .social-share-icons {
        //top: 54%;
        //transform: translateY(-50%);
    }
}

.idea-ratings-section {
  background: #fff;
  border-radius: 5px;
  bottom: 100%;
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 12%);
  box-shadow: 0 0 10px rgb(0 0 0 / 12%);
  position: absolute;
  left: 0;
}

.comment-liked {
  cursor : default;
}

.list-idea {
  $idea-description-lines-n : 2;

  .short-para-2-line{
    min-height: calc(var(--bs-body-font-size) * $idea-description-lines-n * var(--bs-body-line-height));

    @media (max-width: 1600px) {
      min-height: calc(var(--bs-body-font-size) * $idea-description-lines-n * var(--bs-body-line-height));
    }
  }

  .list-idea {
    min-height: 270px;
  }

  .list-idea-footer{
  }

}

.single-reply.submission-by-moderator{
  background-color: #d7f7ff !important;
}

.comment-section,
.idea-comment,
.askus-comment{
  &-content {
    border-radius: 10px;
    &.submission-by-moderator{
      background-color: #d7f7ff !important;
    }
  }

  img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  div.d-flex{
    @extend .align-items-center;
  }

}

.challenge-title{
  @extend .mt-3;
  h1,h2{
    font-weight: 700;
  }
}

$list-idea-title-lines: 2;
$consultation-idea-title-lines: 2;

%idea-title {
  display: -webkit-box;
  line-clamp: $consultation-idea-title-lines;
  overflow: hidden;
  -webkit-line-clamp: $consultation-idea-title-lines;
  -webkit-box-orient: vertical;
}

.view-consultation{
  .idea-title {
    @extend %idea-title;
  }

  .stars_icons{
  }
}
.view-ideas {
  h2.idea-title{
    @extend %idea-title;

    min-height: calc(22px * $list-idea-title-lines * $headings-line-height);
    text-overflow: ellipsis;
  }

}