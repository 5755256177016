.white-card {
  $border-radius : 20px;
  background: $text-white;
  box-shadow: 0px 100px 80px rgba(28, 32, 37, 0.05), 0px 12.5216px 10.0172px rgba(28, 32, 37, 0.025);
  border-radius: $border-radius;

  .widget-header{
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    @extend .p-md-4;
  }

  .widget-content{
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    @extend .p-md-4;

    p:last-child {
      margin-bottom: 0 !important;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .blurb-icon {
    width: 60px; // Requested changes from Geordie
    height: 60px;
  }

  .sponsor-item:last-child {
    .sponsor-email{
      margin-bottom: 0;
    }
  }
}

.widget-type-link {
}

.white-bg {
  background: $text-white;
}