// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.border-line {
    width: 1px;
    height: 97%;
    margin: 0 auto;
    background: #a9afb6;
}

.custom-marker li {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background: $brand-color;
        border-radius: 50%;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.search_box {
    z-index: 2;
    width: 40%;
    top: 74px;
    right: 13%;
    .form-control {
        border: 1px solid #d7d7de;
        border-radius: 10px;
        height: 44px;
        width: 73%;
    }
    .btn-submit {
        top: 0;
        right: 0;
        background: #1c2025;
        border-radius: 0px 10px 10px 0px;
        height: 44px;
        border: 0;
        color: #fff;
        padding: 0 30px;
        //text-transform: uppercase;
    }
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
}

.custom-list.custom-marker {
    padding-left: 15px;
}

.custom-list.custom-marker li:before {
    background: #000;
    left: -15px;
    width: 6px;
    height: 6px;
}

.footer_logo_img {
    height: 40px;
}

//Search
.search-results{
  a{
    font-size: 1.2rem;
    text-decoration: none;
  }
}
.com_search{
  .white-card{
    .search{
      @media screen and (max-width: 600px){
        .ordering-box{
          padding-left: 1rem;
          padding-right: 1rem;
          margin-top: 1.2rem;
        }
        .browse_label{
          padding: 0 .8rem;
        }
      }
      input[type="text"]{
        width: 85%;
        &::placeholder {
          font-size: 14px;
        }
        @media screen and (max-width: 600px) {
          width: auto;
        }
      }
      .browse_label{
        border: 0;
        font-weight: 500 !important;
      }
      label{
        font-weight: 400;
        input[type="checkbox"]{
          margin-right: 3px;
          vertical-align: sub;
        }
        input[type="radio"]{
          vertical-align: middle;
        }
      }
      select{
        font-size: .775rem;
        padding: 0.3rem 0.5rem;
        border-radius: 5px;
        border-color: #ccc;
      }
    }
  }
}
