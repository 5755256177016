.white-card.rounded-top-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select_theme_2 {
    width: 250px;
    height: 40px;
}

.stories-detail-title {
    font-size: $font_size_60;
}

.banner.stories-detail-banner {
    height: 67vh;
    background-position: center;
    background-size: cover;
}

.video-container{
  .play-btn{
    position: absolute;
    top: 45%; left: 43%;
    width: 50px;height: 50px;
  }
  img{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    object-fit: cover;
  }
}

.story {
  &-comment-content{
    border-radius: 10px;
    &.submission-by-moderator{
      background-color: #d7f7ff !important;
    }
  }
}

.story-img{
  max-height: 250px;
  min-height: 200px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.file-remove-image{
  .remove-close{
    cursor: pointer;
  }
}

@media (max-width: 767px){
  .view-story.layout-single {
    .detail-content {
      padding-right: 2rem;

      p {
        font-size: 1.125rem;
      }
    }
  }
}
@media (min-width: 768px) {
  .view-story.layout-single {
    .detail-content {
      padding-right: 2rem;

      p {
        font-size: 1.255rem;
      }
    }
  }
  .stories.banner {
    height: 90%; //550px
  }
}
