.banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*height: 77vh;*/
    height: 65vh;
    & .home-banner-title {
        font-weight: 700;
    }
    &.home{
      background-position: top center;
    }
}