// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */

html {
    box-sizing: border-box;
}

body {
    background: $bg-color;
}


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*,
*::before,
*::after {
    box-sizing: inherit;
}


/**
 * Basic styles for links
 */

a {
    color: $brand-color;
    text-decoration: none;
    @include on-event {
        color: $text-color;
        text-decoration: underline;
    }
}

// font sizes
.fs-20 {
    font-size: $font_size_20;
}

.fs-18 {
    font-size: $font_size_18;
}

.fs-16 {
    font-size: $font_size_16;
}

.fs-14 {
    font-size: $font_size_14;
}

.fs-12 {
    font-size: 0.8rem;
}

.fs-36 {
    font-size: $font_size_36;
}

.fs-30 {
    font-size: $font_size_30;
}

.fs-60 {
    font-size: $font_size_60;
}

.fs-24 {
    font-size: $font-size-24;
}

.fs-5{
  font-size: 1rem;
}

.fs-28{
  font-size: 1.7rem !important;
}

// utilities classes
.bg-yellow {
    background-color: $brand-color;
}

.h-250 {
    height: 250px;
    object-fit: cover;
}

.text_primary {
    color: $brand-color;
}

.list-style-none {
    list-style: none;
}

.bg-style {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-light-overlay:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 45%);
}
.bg-light-overlay *, .bg-light-overlay {
    position: relative;
}
.bg-light-overlay {
    overflow:hidden;
}
.bg_grey {
    background-color: $light-grey;
}
.text-light-grey {
    color: #d5d5d5 !important;
}
.border-1-grey {
    border: 1px solid #c7c7c7;
}

.bg-shade {
    background: $text-white;
}

.rounded-20 {
    border-radius: 20px !important;
}

.rounded-10 {
    border-radius: 10px !important;
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.text-grey {
    color: rgba(142, 142, 142, 1);
}

// font-wieght 
.fw-400 {
    font-weight: 400;
}

.fw-800 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.font_roboto {
    font-family: $font-roboto;
}

.line-height-34 {
    line-height: 34px;
}

.text-justify {
    text-align: justify;
}

label {
    font-weight: 600;
    font-size: $font_size_14;
}

.font-franklin {
    font-family: $font-franklin;
}

.cursor-default{
  cursor: default;
}
.form-check {
    display: block;
    min-height: $form-check-min-height;
    padding-left: $form-check-padding-start;
    margin-bottom: $form-check-margin-bottom;
  
    .form-check-input {
      float: left;
      margin-left: $form-check-padding-start * -1;
    }
    .form-check-input[type=checkbox] {
      margin-left:0px;
    }
}

.bg-overlay-dark, .bg-light-overlay{
  a:not(.custom-btn) {
    color: #fff !important;
    text-decoration: underline;
  }
}

.bg-overlay-dark * , .bg-overlay-light-2 *{
    position: relative;
}

.article {
  background: $white;
}

.document-form .g-recaptcha {
  transform:none;
  transform-origin:0 0;

  @include media-breakpoint-between(lg, xl){
    transform:scale(0.83);
  }
}