/**
 * Basic typography style for copy text
 */

body {
    color: $text-color;
    // font: normal 100% / 1.4 $text-font-stack;
    font-family: $text-font-stack;
    p{
      font-size: $font-size-16 !important;
      @media only screen and (max-width: 768px){
        font-size: $font_size_14 !important;
      }
    }

}

.base-font-color {
  color: $text-color;
}