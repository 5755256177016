.breadcrumb_custom {
    background-color: #D7D7DE;
    & .breadcrumb li {
        font-size: $font_size_18;
        font-weight: 500;
    }
    .breadcrumb{
      .breadcrumb-item.active{
        color: $text-black;
      }
    }
    a {
      color: $brand-color;
    }
}